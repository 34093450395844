<template>
  <div class="keyboard-input">
    <!-- <div class="input">
      <span class="cancel" @click="cancel"><a-icon type="left" /> 返回</span>
    </div> -->

    <div class="tab-list">
      <a-tabs default-active-key="1" type="card">
        <a-tab-pane key="1" tab="注册认证">注册认证</a-tab-pane>
        <a-tab-pane key="2" tab="本人取件">本人取件</a-tab-pane>
        <a-tab-pane key="3" tab="代人取件">代人取件</a-tab-pane>
        <a-tab-pane key="3" tab="暂存包裹">暂存包裹</a-tab-pane>
        <a-tab-pane key="4" tab="寄存包裹">寄存包裹</a-tab-pane>
      </a-tabs>
    </div>
    
    <div class="cancel">返回主页</div>
    <div class="contact">联系客服： {{ $store.state.servicePhone }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    cancel() {
      this.$router.push({
        name: "Home",
        query: {
          id: this.$store.state.dtuId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.keyboard-input {
  width: 1024px;
  height: 768px;
  background-image: url("../assets/logo.png");
  position: relative;
  border: 1px solid #ccc;

  .tab-list {
    margin: 10px;
  }

  .cancel {
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: bolder;
    color: #0096cc;
    border-radius: 50px;
    margin: auto;
    background-color: white;
    width: 240px;
    height: 60px;
    line-height: 60px;
    text-align: center;
    bottom: 50px;
    left: 396px;
    position: absolute;
  }
  .contact {
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    bottom: 20px;
    width: 1024px;
    position: absolute;
  }
}
</style>