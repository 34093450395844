import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dtuId: '',
    courierQrCode: null,
    userQrCode: null,
    dtuId: null,
    servicePhone: null,
    serviceCode: null,
    time: 60,
    imgBaseUrl: 'https://shandikeji.oss-cn-beijing.aliyuncs.com/'
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
