<template>
  <div class="keyboard-input">
    <div class="input">
      <span class="cancel" @click="cancel"><a-icon type="left" /> 返回</span>
      <span class="daojishi"><a-icon type="calendar" /> {{ second }} s</span>
    </div>
    <div class="title">
      <img src="../assets/图层 25.png" />&nbsp; 柜门已开，取件成功！
    </div>
    <div class="qcode">
      <div class="small-title">包裹早知道</div>
      <div class="img">
        <img :src="$store.state.imgBaseUrl + $store.state.userQrCode" />
      </div>
      <div class="warn"><a-icon type="wechat" />微信扫一扫，打开小程序</div>
    </div>
    <div class="contact">联系客服：{{ $store.state.servicePhone }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      second: 10,
      title: "",
      interval: null,
    };
  },
  created() {},
  mounted() {
    this.interval = setInterval(() => {
      this.second--;
      if (this.second <= 0) {
        clearInterval(this.interval);
        this.$router.push({
          name: "Home",
          query: {
            id: this.$store.state.dtuId,
          },
        });
      }
    }, 1000);
  },
  methods: {
    cancel() {
      clearInterval(this.interval);
      this.$router.push({
        name: "Home",
        query: {
          id: this.$store.state.dtuId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.keyboard-input {
  width: 1024px;
  height: 768px;
  background-image: url("../assets/logo.png");
  position: relative;

  .input {
    width: 1024px;
    height: 160px;
    position: relative;
    .cancel {
      position: absolute;
      top: 57px;
      display: inline-block;
      width: 148px;
      height: 69px;
      background-color: white;
      line-height: 69px;
      border-bottom-right-radius: 69px;
      border-top-right-radius: 69px;
      font-size: 33px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1572e0;
      cursor: pointer;
    }
    .daojishi {
      position: absolute;
      top: 57px;
      left: 876px;
      display: inline-block;
      width: 148px;
      height: 69px;
      cursor: pointer;
      background-color: white;
      line-height: 69px;
      border-bottom-left-radius: 69px;
      border-top-left-radius: 69px;
      font-size: 33px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1572e0;
      padding-left: 20px;
    }
  }

  .title {
    font-size: 83px;
    font-family: Microsoft YaHei;
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
  }
  .qcode {
    width: 394px;
    height: 394px;
    margin: 0px auto;
    background: #ffffff;
    border-radius: 46px 46px 46px 46px;
    text-align: center;
    padding-top: 30px;

    .small-title {
      height: 34px;
      font-size: 33px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .img {
      margin-top: 8px;
      img {
        width: 284px;
        height: 283px;
      }
    }
    .warn {
      height: 34px;
      line-height: 34px;
      font-size: 17px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a0a0a0;
    }
  }
  .contact {
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin-top: 20px;
  }
}
</style>