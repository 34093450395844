<template>
  <div class="keyboard-input">
    <a-spin size="large" tip="加载中" :spinning="spinning">
      <div class="input">
        <span class="cancel" @click="cancel"><a-icon type="left" /> 返回</span>
        <span class="daojishi"><a-icon type="calendar" /> {{ second }} s</span>
        <div class="click-code">点击输入取件码</div>
        <div class="number-code">
          <span>{{ numberArray[0] === "" ? "&nbsp;" : numberArray[0] }}</span>
          <span>{{ numberArray[1] === "" ? "&nbsp;" : numberArray[1] }}</span>
          <span>{{ numberArray[2] === "" ? "&nbsp;" : numberArray[2] }}</span>
          <span>{{ numberArray[3] === "" ? "&nbsp;" : numberArray[3] }}</span>
          <span>{{ numberArray[4] === "" ? "&nbsp;" : numberArray[4] }}</span>
          <span>{{ numberArray[5] === "" ? "&nbsp;" : numberArray[5] }}</span>
        </div>
      </div>
      <div class="keyboard">
        <div class="row">
          <span @click="selectNumber(1)">1</span>
          <span @click="selectNumber(2)">2</span>
          <span @click="selectNumber(3)">3</span>
        </div>
        <div class="row">
          <span @click="selectNumber(4)">4</span>
          <span @click="selectNumber(5)">5</span>
          <span @click="selectNumber(6)">6</span>
        </div>
        <div class="row">
          <span @click="selectNumber(7)">7</span>
          <span @click="selectNumber(8)">8</span>
          <span @click="selectNumber(9)">9</span>
        </div>
        <div class="row">
          <span @click="selectNumber(0)">0</span>
          <span @click="selectNumber('delete')">删除</span>
          <span @click="selectNumber('remove')">清空</span>
        </div>
        <!-- <div class="row">
        <span class="span-big">0</span>
        <span class="span-big">删除</span>
      </div> -->
      </div>
      <a-modal
        v-model="visible"
        :title="title"
        ok-text="确认"
        cancel-text="取消"
        :footer="null"
        width="880px"
        :closable="true"
        :maskClosable="false"
        :centered="true"
        @cancel="cancel"
        @ok="visible=false"
      >
        <div style="height: 560px">
          <div class="weixinpay">
            <vue-qr
              ref="wxQrcode1"
              :callback="test1"
              qid="testQrId1"
              :size="250"
              :margin="10"
              :text="wxQrcode"
              class="img"
            ></vue-qr>
          </div>
          <div class="alipay">
            <vue-qr
              ref="aliQrcode1"
              :callback="test1"
              qid="testQrId2"
              :size="250"
              :margin="10"
              :text="aliQrcode"
              class="img"
            ></vue-qr>
          </div>
        </div>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import VueQr from "vue-qr";
export default {
  components: { VueQr },
  data() {
    return {
      numberArray: ["", "", "", "", "", ""],
      currentIndex: 0,
      visible: false,
      second: 60,
      aliQrcode: "",
      wxQrcode: "",
      expressId: "",
      title: "请扫码支付",
      interval: null,
      forcallBackInterval: null,
      spinning: false,
    };
  },
  created() {
    const id = this.$route.query.id;
    if (!this.$store.state.dtuId || this.$store.state.dtuId.length === 0) {
      this.$router.push({
        name: "Home",
        query: {
          id: id,
        },
      });
    }
    this.second = this.$store.state.time;
    this.interval = setInterval(() => {
      this.second--;
      this.title = "请尽快支付，剩余时间:" + this.second + "秒";
      if (this.second <= 0) {
        clearInterval(this.interval);
        if (this.forcallBackInterval) {
          clearInterval(this.forcallBackInterval);
        }
        this.$router.go(-1);
      }
    }, 1000);
  },
  methods: {
    cancel() {
      clearInterval(this.interval);
    },
    test1() {},
    selectNumber(num) {
      if (num === "delete") {
        if (this.currentIndex === 0) {
          return;
        }
        this.numberArray[this.currentIndex - 1] = "";
        this.numberArray = this.numberArray.slice();
        this.currentIndex--;
        return;
      }
      if (num === "remove") {
        if (this.currentIndex === 0) {
          return;
        }
        this.numberArray = ["", "", "", "", "", ""];
        this.numberArray = this.numberArray.slice();
        this.currentIndex = 0;
        return;
      }
      this.numberArray[this.currentIndex] = num;
      this.numberArray = this.numberArray.slice();

      if (this.currentIndex == 6) {
        return;
      }

      if (this.currentIndex == 5) {
        this.currentIndex++;
        this.openLock();
        return;
      }
      this.currentIndex++;
    },
    cancel() {
      clearInterval(this.interval);
      this.$router.go(-1);
    },
    forCallBack() {
      this.forcallBackInterval = setInterval(() => {
        const acceptNum = this.numberArray.join("");
        this.$axios
          .get("business/express/findOpenCabinetStatus/" + this.expressId)
          .then((res) => {
            console.log(res);
            const data = res.data;
            if (data.code !== 200) {
              this.$message.error("发生异常，如有问题请联系管理员");
              return;
            }
            if (data.msg === "50002") {
              clearInterval(this.interval);
              clearInterval(this.forcallBackInterval);
              this.$router.push({
                name: "Success",
                query: {
                  id: this.$store.state.dtuId,
                },
              });
              return;
            }
          });
      }, 1500);
    },
    openLock() {
      this.spinning = true;
      const acceptNum = this.numberArray.join("");
      this.$axios
        .get(
          "business/express/findByDtuIdAndAccNum?dtuId=" +
            this.$store.state.dtuId +
            "&acceptNum=" +
            acceptNum
        )
        .then((res) => {
          console.log(res);
          const data = res.data;
          if (data.code !== 200) {
            this.spinning = false;
            this.$message.error("发生异常，如有问题请联系管理员");
            return;
          }
          // 快递已取或不存在
          if (data.msg === "50000") {
            this.spinning = false;
            this.$error({
              title: "温馨提示",
              content: "取件码不存在或已被取走，如有问题请咨询客服。",
              okText: "确定",
            });
            return;
          }
          // 需要支付
          else if (data.msg === "50001") {
            // 生成二维码
            if (data.data.wxUrl && data.data.wxUrl.length > 0) {
              this.wxQrcode = data.data.wxUrl;
            }
            if (data.data.alUrl && data.data.alUrl.length > 0) {
              this.aliQrcode = data.data.alUrl;
            }
            this.expressId = data.data.expressId;
            this.visible = true;
            this.second = this.$store.state.time;
            this.spinning = false;
            this.forCallBack();
          }
          // 开柜
          else if (data.msg === "50002") {
            clearInterval(this.interval);
            this.spinning = false;
            if (this.forcallBackInterval != null) {
              clearInterval(this.forcallBackInterval);
            }
            this.$router.push({
              name: "Success",
              query: {
                id: this.$store.state.dtuId,
              },
            });
            return;
          }
        });
    },
  },
};

// this.$success({
//   title: "温馨提示",
//   content: "柜门已打开，请尽快取走您的包裹，并关闭柜门",
//   okText: "确定",
// });
</script>

<style lang="scss" scoped>
.keyboard-input {
  width: 1024px;
  height: 768px;
  background-image: url("../assets/logo.png");
  position: relative;

  .input {
    width: 1024px;
    height: 400px;
    position: relative;
    .cancel {
      position: absolute;
      top: 57px;
      display: inline-block;
      width: 148px;
      height: 69px;
      background-color: white;
      line-height: 69px;
      border-bottom-right-radius: 69px;
      border-top-right-radius: 69px;
      font-size: 33px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1572e0;
      cursor: pointer;
    }
    .daojishi {
      position: absolute;
      top: 57px;
      left: 876px;
      display: inline-block;
      width: 148px;
      height: 69px;
      cursor: pointer;
      background-color: white;
      line-height: 69px;
      border-bottom-left-radius: 69px;
      border-top-left-radius: 69px;
      font-size: 33px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1572e0;
      padding-left: 20px;
    }

    .click-code {
      position: absolute;
      top: 160px;
      left: 425px;
      width: 175px;
      height: 25px;
      font-size: 25px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 216px;
    }

    .number-code {
      position: absolute;
      top: 300px;
      left: 215px;
      span {
        text-align: center;
        display: inline-block;
        width: 60px;
        margin: 20px;
        font-size: 42px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: white;
        border-bottom: 3px solid white;
      }
    }
  }
  .keyboard {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    background-color: white;
    width: 1024px;
    height: 366px;
    padding-left: 132px;
    padding-top: 10px;

    span:active {
      background-color: #d0d0d0;
    }
    span {
      cursor: pointer;
      display: inline-block;
      width: 236px;
      height: 71px;
      margin: 8px;
      border: 2px solid #d0d0d0;
      border-radius: 8px;

      font-size: 42px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1572e0;
      text-align: center;
      line-height: 71px;
    }

    .span-big {
      width: 362px;
      height: 75px;
    }
  }
}

.weixinpay {
  background-image: url("../assets/weixinpay.png");
  width: 400px;
  height: 555px;
  position: absolute;
  left: 25x;

  .img {
    position: relative;
    left: 74px;
    top: 117px;
  }
}
.alipay {
  background-image: url("../assets/alipay.png");
  width: 400px;
  height: 555px;
  position: absolute;
  right: 25px;
  .img {
    position: relative;
    left: 74px;
    top: 117px;
  }
}
</style>