import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import KeyboardInput from '../views/KeyboardInput.vue'
import DepositPackage from '../views/DepositPackage.vue'
import Success from '../views/Success.vue'
import Help from '../views/Help.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/KeyboardInput',
    name: 'KeyboardInput',
    component: KeyboardInput
  },
  {
    path: '/DepositPackage',
    name: 'DepositPackage',
    component: DepositPackage
  },
  {
    path: '/Success',
    name: 'Success',
    component: Success
  },
  {
    path: '/Help',
    name: 'Help',
    component: Help
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
