<template>
  <div class="keyboard-input">
    <div class="input">
      <span class="cancel" @click="cancel"><a-icon type="left" /> 返回</span>
      <span class="daojishi"><a-icon type="calendar" /> {{ second }} s</span>
    </div>
    <div class="qcode">
      <div class="title">{{ title }}</div>
      <div v-if="imgSrc.length > 0" class="img">
        <img :src="$store.state.imgBaseUrl + imgSrc" />
      </div>
      <div class="warn"><a-icon type="wechat" />微信扫一扫，打开小程序</div>
    </div>
    <div class="contact">联系客服： {{ $store.state.servicePhone }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      second: 60,
      title: "",
      interval: null,
      imgSrc: "",
    };
  },
  created() {
    const id = this.$route.query.id;
    const type = this.$route.query.type;
    if (!this.$store.state.dtuId || this.$store.state.dtuId.length === 0) {
      this.$router.push({
        name: "Home",
        query: {
          id: id,
        },
      });
    }

    this.second = this.$store.state.time;
    if (type == 1) {
      this.title = "快递员";
      this.imgSrc = this.$store.state.courierQrCode;
    } else if (type == 3) {
      this.title = "暂存包裹";
      this.imgSrc = this.$store.state.userQrCode;
    } else if (type == 4) {
      this.title = "寄存包裹";
      this.imgSrc = this.$store.state.userQrCode;
    }

    this.interval = setInterval(() => {
      this.second--;
      if (this.second <= 0) {
        clearInterval(this.interval);
        this.$router.go(-1);
      }
    }, 1000);
  },
  methods: {
    cancel() {
      clearInterval(this.interval);
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.keyboard-input {
  width: 1024px;
  height: 768px;
  background-image: url("../assets/logo.png");
  position: relative;

  .input {
    width: 1024px;
    height: 218px;
    position: relative;
    .cancel {
      position: absolute;
      top: 57px;
      display: inline-block;
      width: 148px;
      height: 69px;
      background-color: white;
      line-height: 69px;
      border-bottom-right-radius: 69px;
      border-top-right-radius: 69px;
      font-size: 33px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1572e0;
      cursor: pointer;
    }
    .daojishi {
      position: absolute;
      top: 57px;
      left: 876px;
      display: inline-block;
      width: 148px;
      height: 69px;
      cursor: pointer;
      background-color: white;
      line-height: 69px;
      border-bottom-left-radius: 69px;
      border-top-left-radius: 69px;
      font-size: 33px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #1572e0;
      padding-left: 20px;
    }
  }

  .qcode {
    width: 425px;
    height: 425px;
    background: #ffffff;
    border-radius: 50px;
    margin: 0px auto;
    text-align: center;
    padding-top: 30px;

    .title {
      height: 40px;
      line-height: 40px;
      font-size: 33px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
    }
    .img {
      margin-top: 8px;
      img {
        width: 286px;
        height: 286px;
        border: dotted 2px #ccc;
      }
    }
    .warn {
      height: 34px;
      line-height: 34px;
      font-size: 17px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a0a0a0;
    }
  }
  .contact {
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin-top: 70px;
  }
}
</style>