import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'ant-design-vue/dist/antd.css';

import axios from 'axios'
// axios.defaults.baseURL = 'http://192.168.2.21:8080'
// axios.defaults.baseURL = 'https://api.boruisoft.com'
axios.defaults.baseURL = 'https://api.sxsdznkj.com'
// 全局注册，之后可在其他组件中通过 this.$axios 发送数据
Vue.prototype.$axios = axios

Vue.config.productionTip = false

import { message, notification, Modal, Tabs, Spin, Button, Icon } from 'ant-design-vue'

Vue.use(Button)
Vue.use(Icon)
Vue.use(Modal)
Vue.use(Tabs)
Vue.use(Spin)

Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning

message.config({
  duration: 2,// 持续时间
  top: `100px`, // 到页面顶部距离
  maxCount: 3 // 最大显示数, 超过限制时，最早的消息会被自动关闭
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
