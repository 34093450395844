<template>
  <div class="home">
    <div class="title">闪递智能柜</div>

    <div class="content">
      <div class="operation" @click="jumpLink(1)">
        <img style="width: 88px; height: 100px" src="../assets/kdy.png" />
        <div>快递员</div>
      </div>
      <div class="operation" @click="jumpLink(2)">
        <img src="../assets/qbg.png" />
        <div>取包裹</div>
      </div>
      <div class="operation" @click="jumpLink(3)">
        <img src="../assets/zcbg.png" />
        <div>暂存包裹</div>
      </div>
      <div class="operation" @click="jumpLink(4)">
        <img src="../assets/jcbg.png" />
        <div>寄存包裹</div>
      </div>
    </div>
    <!-- <div class="help" @click="navHelp">使用说明</div> -->
    <div class="contact">联系客服： {{ $store.state.servicePhone }}</div>
    <div class="version" @click="refresh">当前版本：v1.0.2</div>
    
    <div class="wx-img">
      <img
        style="width: 150px; height: 150px"
        :src="$store.state.imgBaseUrl + $store.state.userQrCode"
      />
      <div>
        微信扫一扫
        <br />
        一键取件
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  mounted() {
    let id = this.$route.query.id;
    if (!id || id.length == 0) {
      this.$message.error("请输入正确的链接地址，如有问题，请联系管理员");
      return;
    }
    this.getDtuInfo(id);
  },
  methods: {
    /**
     * 1 快递员
     * 2 取包裹
     * 3 暂存包裹
     * 4 寄存包裹
     */
    jumpLink(type) {
      if (!this.$store.state.dtuId && this.$store.state.dtuId.length == 0) {
        this.$message.error("当前地址错误，无法操作，请联系管理员");
        return;
      }
      if (type === 2) {
        this.$router.push({
          name: "KeyboardInput",
          query: {
            id: this.$store.state.dtuId,
          },
        });

        return;
      }
      this.$router.push({
        name: "DepositPackage",
        query: {
          type: type,
          id: this.$store.state.dtuId,
        },
      });

      return;
    },
    refresh() {
      window.location.reload()
    },
    navHelp() {
      this.$router.push({
        name: "Help",
        query: {
          id: this.$store.state.dtuId,
        },
      });
    },
    getDtuInfo(id) {
      this.$axios.get("basic/findDtuById/" + id).then((res) => {
        if (res.data.code !== 200) {
          this.$message.error("请输入正确的链接地址，如有问题，请联系管理员");
          return;
        }
        const data = res.data.data;
        this.$store.state.courierQrCode = data.qr_code;
        this.$store.state.userQrCode = data.user_qr_code;
        this.$store.state.serviceCode = data.service_code;
        this.$store.state.servicePhone = data.service_phone;
        this.$store.state.time = parseInt(data.time);
        this.$store.state.dtuId = id;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  width: 1024px;
  height: 768px;
  position: relative;
  background-image: url("../assets/logo.png");

  .title {
    font-size: 58px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 216px;
  }

  .content {
    padding: 0px 134px;
    margin-top: -35px;
    .operation {
      width: 220px;
      height: 220px;
      margin-left: 105px;
      background-color: #ffffff;
      border-radius: 220px;
      display: inline-block;
      margin-top: 35px;
      text-align: center;
      cursor: pointer;

      img {
        margin-top: 30px;
        margin-bottom: 10px;
      }
      div {
        font-size: 33px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1572e0;
        text-align: center;
      }
    }

    .operation:active {
      background-color: #d0d0d0;
    }
  }

  .contact {
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    margin-top: 20px;
  }

  .version {
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    right: 8px;
    bottom: 20px;
    position: absolute;
  }

  .wx-img {
    width: 150px;
    height: 150px;
    position: absolute;
    bottom: 60px;
    left: 20px;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }

  .help {
    position: absolute;
    right: 10px;
    bottom: 30px;
    font-size: 17px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>